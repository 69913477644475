import axios from "axios";

export const getCategoriesReducer = (state= {categoriesia:[]},action)=>{

  switch(action.type){
    case "GET_ALL_CATEGORIES_TYPE":
      return {categoriesia: action.categoriesAc}
    default:
      return state
  }
  
}
export const AddCategoriesReducer = (state= {categoriesia:""},action)=>{

  switch(action.type){
    case "ADD_NEW_CATEGORIES_TYPE":
      return {resoia : action.resAC, categoriesia:action.categoriesAc}
    default:
      return state
  }
  
}
/**
       const link ='http://localhost/react/frank-cafe/api/view.php?itemListOf=categories';
      const res = await axios.get(link)
 dispach({type:"HELLO", categoriesia: res.data.categories})
 
      */