import { createStore,applyMiddleware } from "redux";
import { combineReducers } from "redux";
// import { composeWithDevTools } from "redux-devtools-extension"; composeWithDevTools()

import thunk from "redux-thunk";

import { backgroundMusicReducer } from "./BackgroundMusic";

import { getCategoriesReducer } from "./GetCategories";

const reducers = combineReducers({
  GETMUSICREDUCER:backgroundMusicReducer,
  GETCATEGORIESREDUCER: getCategoriesReducer
})
export const redSto = createStore(reducers, applyMiddleware(thunk))