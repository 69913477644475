export const backgroundMusicReducer = (state={counter:1,menuStartStopButton:"▶",homeStartStopButton:"تشغيل الموسيقي"},action)=>{
  if(action.type==="PLAYANDPAUSE"){
    var newMenuStartStopButton = state.menuStartStopButton
    // var newHomeStartStopButton = state.homeStartStopButton
    if(state.counter % 2 === 1){
      newMenuStartStopButton = "◼";
    }else{
      newMenuStartStopButton = "▶";
    }
    return {counter:state.counter + 1,menuStartStopButton:newMenuStartStopButton}
  }
  return state;
}