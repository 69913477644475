import React from 'react'
import globalData from '../data/global'

const SocialMedia = () => {
  return (
    <div>
      <span><a href="https://www.instagram.com/frank.rest.cafe/" target="_blank" rel="noreferrer"><img src="insta.png" alt="instaLogo" style={{width:"20px",height:"20px",}}/> {globalData.followUsOnInsta}</a></span>
    </div>
  )
}

export default SocialMedia
