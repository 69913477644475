import axios from "axios";


export const getAllCategories = () => {
  return async (dispach)=>{
    const res =await axios.get("http://localhost/react/frank-cafe/api/view.php?itemListOf=categories");
    dispach({type:"GET_ALL_CATEGORIES_TYPE", categoriesAc:res.data.categories})
  }
}


export const addNewCategory = (nameOfItemCategory) => {
  return async (dispach)=>{
    const addNewCategory = await axios.post("http://localhost/react/frank-cafe/api/post",{ nameOfItemCategory: nameOfItemCategory },{ headers: { 'content-type': 'multipart/form-data' } })
    dispach({type:"ADD_NEW_CATEGORIES_TYPE", resAC:addNewCategory})
  }
}


