import React, { useEffect } from 'react'
import { useState } from 'react';
import { useSelector,useDispatch } from 'react-redux'
import backgroundSound from '../audio/background.mp3'
const audio = new Audio(backgroundSound);



const BackgroundMusic = () => {
const usegredstate = useSelector(state=>state.GETMUSICREDUCER)
const dispatchbgredstate = useDispatch()
  const [bgAudio, setBgAudio] = useState({audio})
  useEffect(()=>{
    setBgAudio(audio)
  },[])
  const playMusic=()=>{
    bgAudio.loop = true;
    return bgAudio.play();
  }
  const stopMusic=()=>{
    return bgAudio.pause();
  }
  
  const playPauseFun = ()=>{
    dispatchbgredstate({type:'PLAYANDPAUSE'})
    if(usegredstate.counter% 2 === 1){
      return playMusic()
    }else{
      return stopMusic()
    }
  }

  return (
    <div className="backgroundMusicButton">
      <button onClick={playPauseFun}>{usegredstate.menuStartStopButton}</button>
    </div>
  )
}

export default BackgroundMusic
