import React from 'react'
import globalData from '../data/global'
import HaliIliya from '../components/HaliIliya'
const MQ = () => {
  return (
      <div className="viewScreenMessage">
        <br></br> {globalData.thisWebPageNotSupportingThisSizeOfScreens} <br></br><br></br><img src="crying.gif" alt="crying" width="90"/>
        <HaliIliya/>
      </div>
      
  )
}

export default MQ
