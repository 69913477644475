import React from 'react'

const FrankCafe = () => {
  return (
    <div className="appLogo">
    <img src="frank-logo.png" alt="frank-logo"/>
    </div>      
  )
}

export default FrankCafe
