import React from 'react'
import { Carousel } from 'react-bootstrap'
Carousel.control = false
const carousel = () => {

  return (
    <div>
      <Carousel  fade pause="hover" interval="1000" variant="">
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="img1.jpg"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="img2.jpg"
          alt="Second slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="img3.jpg"
          alt="Third slide"
        />

      </Carousel.Item>
    </Carousel>
    </div>
  )
}

export default carousel
