const globalData = {
  welcome: "اهلاً وسهلاً بكم في فرانك كافيه",
  firstHPAD:"اعلان رقم 1: هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل",
  secondHPAD:"اعلان رقم 2: نالك العديد من الأنواع المتوفرة لنصوص لوريم إيبسوم، ولكن الغالبية تم تعديلها بشكل ما عبر إدخال بعض النوادر",
  menuButtonName:"افتح المنيو",
  todaysOffer:"عرض اليوم، لو تلحك لو ماتلحك! ",
  
  goHome:"الرئيسية",
  thisWebPageNotSupportingThisSizeOfScreens: "هذا الموقع لا يعمل في هذا الجهاز",
  item: "عناصر",
  oldPrice:"السعر القديم",
  pauseMusic:"🎹 ايقاف الموسيقي",
  playMusic:"🎹 تشغيل الموسيقي",
  followUsOnInsta: "تابعنا على الأنستجرام"
}

export default globalData