const menuItemsCategories = [
  {id:1,cat:"المشروبات الساخنة",image:"images/categories/img.jpg"},
  {id:2,cat:"قهوة مثلجة",image:"images/categories/img.jpg"},
  {id:3,cat:"ميلك شيك ",image:"images/categories/img.jpg"},
  {id:4,cat:"عصير طبيعي",image:"images/categories/img.jpg"},
  {id:5,cat:"المشروبات الغازية",image:"images/categories/img.jpg"},
  {id:6,cat:"موهيتو",image:"images/categories/img.jpg"},
  {id:7,cat:"سموزي",image:"images/categories/img.jpg"},
  {id:8,cat:"فرابي",image:"images/categories/img.jpg"},
  {id:9,cat:"الحلويات",image:"images/categories/img.jpg"},
  {id:10,cat:"سندويشات",image:"images/categories/img.jpg"},
  {id:11,cat:"وجبات",image:"images/categories/img.jpg"},
  {id:12,cat:"بيتزا",image:"images/categories/img.jpg"},
  {id:13,cat:"مقبلات",image:"images/categories/img.jpg"},
  {id:14,cat:"اراكيل",image:"images/categories/img.jpg"},
]

const menuItems = [

  // Cat: 1
  {id:1,cat:1,image:"images/items/img.jpg",item:"شوكولاته ايطاليا",price:"25000 IQD",disc:"2500 IQD"},
  {id:2,cat:1,image:"images/items/img.jpg",item:"شاي",price:"1000 IQD",disc:"500 IQD"},
  {id:3,cat:1,image:"images/items/img.jpg",item:"قهوة تركية",price:"2000 IQD",disc:""},
  {id:4,cat:1,image:"images/items/img.jpg",item:"اسبريسو",price:"2000 IQD",disc:""},
  {id:5,cat:1,image:"images/items/img.jpg",item:"امريكانو",price:"2000 IQD",disc:""},
  {id:6,cat:1,image:"images/items/img.jpg",item:"نسكافيه",price:"2500 IQD",disc:""},
  {id:7,cat:1,image:"images/items/img.jpg",item:"كابتشينو",price:"2500 IQD",disc:""},
  {id:8,cat:1,image:"images/items/img.jpg",item:"هوت شوكلت",price:"2500 IQD",disc:""},
  {id:9,cat:1,image:"images/items/img.jpg",item:"سحلب",price:"2500 IQD",disc:""},
  {id:10,cat:1,image:"images/items/img.jpg",item:"كافي لاتيه",price:"2500 IQD",disc:"2500 IQD"},
  {id:11,cat:1,image:"images/items/img.jpg",item:"نومي بصرة",price:"2000 IQD",disc:"2500 IQD"},

  // Cat: 2
  {id:12,cat:2,image:"images/items/img.jpg",item:"ايس كافيه",price:"3500 IQD",disc:""},
  {id:13,cat:2,image:"images/items/img.jpg",item:"ايس كابتشينو",price:"3500 IQD",disc:""},
  {id:14,cat:2,image:"images/items/img.jpg",item:"ايس لاتيه",price:"3500 IQD",disc:""},
  {id:15,cat:2,image:"images/items/img.jpg",item:"ايس كراميل",price:"3500 IQD",disc:""},
  {id:16,cat:2,image:"images/items/img.jpg",item:"ايس شوكولاته",price:"3500 IQD",disc:""},
  {id:17,cat:2,image:"images/items/img.jpg",item:"ايس فانيلا",price:"3500 IQD",disc:""},
  {id:18,cat:2,image:"images/items/img.jpg",item:"ايس موكا",price:"3500 IQD",disc:""},
  {id:19,cat:2,image:"images/items/img.jpg",item:"ايس تي",price:"3500 IQD",disc:""},

  // Cat: 3
  {id:20,cat:3,image:"images/items/img.jpg",item:"ميلك شيك اوريو",price:"4000 IQD",disc:""},
  {id:21,cat:3,image:"images/items/img.jpg",item:"ميلك شيك لوتس",price:"4000 IQD",disc:""},
  {id:22,cat:3,image:"images/items/img.jpg",item:"ميلك شيك نوتيلا",price:"4000 IQD",disc:""},
  {id:23,cat:3,image:"images/items/img.jpg",item:"ميلك شيك كيت كات",price:"4000 IQD",disc:""},
  {id:24,cat:3,image:"images/items/img.jpg",item:"ميلك شيك شوكولاتا",price:"4000 IQD",disc:""},
  {id:25,cat:3,image:"images/items/img.jpg",item:"ميلك شيك فراولة",price:"4000 IQD",disc:""},

 // Cat: 4
 {id:26,cat:4,image:"images/items/img.jpg",item:"عصير برتقال",price:"4000 IQD",disc:""},
 {id:27,cat:4,image:"images/items/img.jpg",item:"عصير ليمون",price:"4000 IQD",disc:""},
 {id:28,cat:4,image:"images/items/img.jpg",item:"عصير رمان",price:"4000 IQD",disc:""},
 {id:29,cat:4,image:"images/items/img.jpg",item:"عصير فراولة",price:"4000 IQD",disc:""},
 {id:30,cat:4,image:"images/items/img.jpg",item:"عصير موز مع حليب",price:"4000 IQD",disc:""},
 {id:31,cat:4,image:"images/items/img.jpg",item:"عصير كوكتيل فواكه",price:"4000 IQD",disc:""},

  // Cat: 5
  {id:32,cat:5,image:"images/items/img.jpg",item:"تايجر",price:"4000 IQD",disc:""},
  {id:33,cat:5,image:"images/items/img.jpg",item:"مكسيكي",price:"4000 IQD",disc:""},
  {id:34,cat:5,image:"images/items/img.jpg",item:"ريدبول",price:"4000 IQD",disc:""},
  {id:35,cat:5,image:"images/items/img.jpg",item:"ريدبول مكسيكي",price:"4000 IQD",disc:""},
  {id:36,cat:5,image:"images/items/img.jpg",item:"كوكاكولا",price:"4000 IQD",disc:""},
  {id:37,cat:5,image:"images/items/img.jpg",item:"سبرايت",price:"4000 IQD",disc:""},
  {id:38,cat:5,image:"images/items/img.jpg",item:"فانتا",price:"4000 IQD",disc:""},
  {id:39,cat:5,image:"images/items/img.jpg",item:"صودا",price:"4000 IQD",disc:""},
  {id:40,cat:5,image:"images/items/img.jpg",item:"ماء",price:"4000 IQD",disc:""},

  // Cat: 6
  {id:41,cat:6,image:"images/items/img.jpg",item:"موهيتو فراولة",price:"3500 IQD",disc:""},
  {id:42,cat:6,image:"images/items/img.jpg",item:"موهيتو مانجو",price:"3500 IQD",disc:""},
  {id:43,cat:6,image:"images/items/img.jpg",item:"موهيتو كرز",price:"3500 IQD",disc:""},
  {id:44,cat:6,image:"images/items/img.jpg",item:"موهيتو ليمون نعناع",price:"3500 IQD",disc:""},
  {id:45,cat:6,image:"images/items/img.jpg",item:"موهيتو بلو",price:"3500 IQD",disc:"2500 IQD"},

  // Cat: 7
  {id:46,cat:7,image:"images/items/img.jpg",item:"سموزي فراولة",price:"3500 IQD",disc:""},
  {id:47,cat:7,image:"images/items/img.jpg",item:"سموزي بلوبيري",price:"3500 IQD",disc:""},
  {id:48,cat:7,image:"images/items/img.jpg",item:"سموزي موز",price:"3500 IQD",disc:""},
  {id:49,cat:7,image:"images/items/img.jpg",item:"سموزي شوكولاته",price:"3500 IQD",disc:""},
  {id:50,cat:7,image:"images/items/img.jpg",item:"سموزي اناناس",price:"3500 IQD",disc:""},

  // Cat: 8
  {id:46,cat:8,image:"images/items/img.jpg",item:"فرابي شوكولاتث",price:"3500 IQD",disc:""},
  {id:47,cat:8,image:"images/items/img.jpg",item:"فرابي قهوة",price:"3500 IQD",disc:""},
  {id:48,cat:8,image:"images/items/img.jpg",item:"فرابي موكا",price:"3500 IQD",disc:""},
  {id:49,cat:8,image:"images/items/img.jpg",item:"فرابي فانيلا",price:"3500 IQD",disc:""},
  {id:50,cat:8,image:"images/items/img.jpg",item:"فرابي كراميك",price:"3500 IQD",disc:""},

  // Cat: 9
  {id:51,cat:9,image:"images/items/img.jpg",item:"ايس كريم شوكولاته",price:"3500 IQD",disc:""},
  {id:52,cat:9,image:"images/items/img.jpg",item:"ايس كريم فواكه",price:"3500 IQD",disc:""},
  {id:53,cat:9,image:"images/items/img.jpg",item:"كيك + ايس كريم",price:"3500 IQD",disc:""},
  {id:54,cat:9,image:"images/items/img.jpg",item:"كيك",price:"3500 IQD",disc:""},
  {id:55,cat:9,image:"images/items/img.jpg",item:"بقلاوة",price:"3500 IQD",disc:""},
  {id:56,cat:9,image:"images/items/img.jpg",item:"كنافة",price:"3500 IQD",disc:""},
  {id:57,cat:9,image:"images/items/img.jpg",item:"مكسرات",price:"3500 IQD",disc:""},

  // Cat: 10
  {id:58,cat:10,image:"images/items/img.jpg",item:"همبكر لحم",price:"3500 IQD",disc:""},
  {id:59,cat:10,image:"images/items/img.jpg",item:"همبكر دجاج",price:"3500 IQD",disc:""},
  {id:60,cat:10,image:"images/items/img.jpg",item:"شاورما لحم",price:"3500 IQD",disc:""},
  {id:61,cat:10,image:"images/items/img.jpg",item:"شاورما دجاج",price:"3500 IQD",disc:"2500 IQD"},
  {id:62,cat:10,image:"images/items/img.jpg",item:"فاهيتا لحم",price:"3500 IQD",disc:""},
  {id:63,cat:10,image:"images/items/img.jpg",item:"فاهيتا دجاج",price:"3500 IQD",disc:""},
  {id:64,cat:10,image:"images/items/img.jpg",item:"سمك تونا",price:"3500 IQD",disc:""},
  {id:65,cat:10,image:"images/items/img.jpg",item:"فنكر",price:"3500 IQD",disc:""},

  // Cat: 11
  {id:66,cat:11,image:"images/items/img.jpg",item:"قلي لحم",price:"8000 IQD",disc:""},
  {id:67,cat:11,image:"images/items/img.jpg",item:"قلي دجاج",price:"6000 IQD",disc:""},
  {id:68,cat:11,image:"images/items/img.jpg",item:"فاهيتا لحم",price:"8000 IQD",disc:""},
  {id:69,cat:11,image:"images/items/img.jpg",item:"فاهيتا دجاج",price:"6000 IQD",disc:""},
  {id:70,cat:11,image:"images/items/img.jpg",item:"سكالوب",price:"7000 IQD",disc:""},
  {id:71,cat:11,image:"images/items/img.jpg",item:"كبة مصلاوية",price:"6000 IQD",disc:""},
  {id:72,cat:11,image:"images/items/img.jpg",item:"بورك وبتيتة جاب",price:"7000 IQD",disc:"2500 IQD"},
  {id:73,cat:11,image:"images/items/img.jpg",item:"كبة حلب",price:"7000 IQD",disc:""},

  // Cat: 12
  {id:74,cat:12,image:"images/items/img.jpg",item:"بيتزا لحم",price:"8000 IQD",disc:""},
  {id:75,cat:12,image:"images/items/img.jpg",item:"بيتزا دجاج",price:"7000 IQD",disc:""},
  {id:76,cat:12,image:"images/items/img.jpg",item:"بيتزا بابيروني",price:"7000 IQD",disc:""},
  {id:77,cat:12,image:"images/items/img.jpg",item:"بيتزا مشكل",price:"8000 IQD",disc:""},
  {id:78,cat:12,image:"images/items/img.jpg",item:"بيتزا خضراوات",price:"7000 IQD",disc:"2500 IQD"},

  // Cat: 13
  {id:79,cat:13,image:"images/items/img.jpg",item:"سلطة يونانية",price:"8000 IQD",disc:""},
  {id:80,cat:13,image:"images/items/img.jpg",item:"سلطة سيزار",price:"7000 IQD",disc:""},
  {id:81,cat:13,image:"images/items/img.jpg",item:"سلطة فرنسية",price:"7000 IQD",disc:""},
  {id:82,cat:13,image:"images/items/img.jpg",item:"سلطة فتوش",price:"8000 IQD",disc:""},
  {id:83,cat:13,image:"images/items/img.jpg",item:"سلطة شرقية",price:"7000 IQD",disc:""},

  // Cat: 14
  {id:84,cat:14,image:"images/items/img.jpg",item:"علك ونعناع",price:"7000 IQD",disc:""},
  {id:85,cat:14,image:"images/items/img.jpg",item:"ليمون ونعناع",price:"7000 IQD",disc:""},
  {id:86,cat:14,image:"images/items/img.jpg",item:"تفاحتين",price:"7000 IQD",disc:""},
  {id:87,cat:14,image:"images/items/img.jpg",item:"عنب",price:"7000 IQD",disc:""},
  {id:88,cat:14,image:"images/items/img.jpg",item:"خلطة فرانك",price:"7000 IQD",disc:""},
  {id:89,cat:14,image:"images/items/img.jpg",item:"اركيلة طبيعي",price:"10000 IQD",disc:"2500 IQD"},

]

export {menuItemsCategories,menuItems}