import React, { useEffect, useState } from 'react';
import { Container, Form, Button, Row, Col } from 'react-bootstrap';
import { useSelector,useDispatch } from 'react-redux';
import { getAllCategories,addNewCategory } from '../Redux/Actions';

const NewItem = () => {
	const [nameOfItemCategory, setNameOfItemCategory] = useState('');
	const [categoriesList, setCategoriesList] = useState([]);
	 
	const dispach = useDispatch()



	useEffect(() => {
		dispach(getAllCategories())
	}, []);
	const categories = useSelector((state)=>state.GETCATEGORIESREDUCER.categoriesia)
	useEffect(() => {
		dispach(getAllCategories())
		setCategoriesList(categories)
	}, [categories]);

	const handleSubmit = (e) => {
		e.preventDefault();
		dispach(addNewCategory(nameOfItemCategory))
		
	}

	return (
		<Container>
			<Form onSubmit={handleSubmit}>
				<fieldset>
					<Form.Group className="mb-3">
						<Form.Control
							vlaue={nameOfItemCategory}onChange={(e) => setNameOfItemCategory(e.target.value)}placeholder="اكتب اسم الفئة"/>
					</Form.Group>
					<Button type="submit">Submists</Button>
				</fieldset>
			</Form>
			{categoriesList.length >= 1
				? categoriesList.map((item) => {
						return (
                <div key={item.categoryId} className="mt2">
                  <Row>
                    <Col xs="2"># {item.categoryId}</Col>
                    <Col xs="10">{item.categoryName}</Col>
                  </Row>
                </div>
						);
				  })
				: <h3><br></br>لا يوجد فئات</h3>
			}
		</Container>
	);
};

export default NewItem;





	// const getCategories = async () => {
	// 	const res = await axios.get("http://localhost/react/frank-cafe/api/view.php?itemListOf=categories").catch((e) => console.log(e));
	// 	setCategoriesList(res.data.categories);
	// };




	// const handleSubmit = async (e) => {
	// 	e.preventDefault();
	// 	const link = 'http://localhost/react/frank-cafe/api/post';
	// 	const res = await axios
	// 		.post(
	// 			link,
	// 			{ nameOfItemCategory: nameOfItemCategory },
	// 			{ headers: { 'content-type': 'multipart/form-data' } }
	// 		)
	// 	if (res.data === 'CATEGORY_ALREADY_EXISTS') {
	// 		alert('Exists');
	// 	} else if (res.data === 'NEW_CATEGORY_ADDED') {
  //     //getCategories()
	// 	} else {
	// 		//console.log(res);
	// 	}
	// };