import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { redSto } from './components/Redux/ReduxStoer'
import { Provider } from 'react-redux'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

    <Provider store={redSto}>
      <App />
    </Provider>

);