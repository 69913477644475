import React from 'react'
import { Link } from 'react-router-dom'
import MQ from '../components/MQ'
import HaliIliya from '../components/HaliIliya'
import FrankCafe from './FrankCafe'
import BackgroundMusic from './BackgroundMusic'




const HomePage = ({globalData}) => {
  return (
<div>
    <div className="container" >

      <FrankCafe/>
      {globalData.welcome}
      <br></br>
      <br></br>
      <Link to="/menu"><div className="openMenuButton"><button className="btn-64"><span>{globalData.menuButtonName}</span></button></div></Link>
      <br></br>
      <div className="backgroundMusicButtonHomePage"><BackgroundMusic/></div>
      

      
      <div className="firstHPAD">
      {globalData.firstHPAD}
      </div>
      <div className="secondHPAD">
      {globalData.secondHPAD}
      </div>
      <HaliIliya/>
      <Link to="/new">New!</Link>
    </div>
    <MQ/>
    
    </div>
  )
}

export default HomePage
